/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* -------------------------------------------app.css--------------------------------------------------- */

:root {
  /* --primary: #e70d00; */
  /* --primary: #ffffff; */
  --dark-1: #17161d;
  --dark-2: #1c1b21;
  --dark-3: #333333;
  --dark-4: #2f2e31;
  --dark-5: #1e1d24;
  --dark-6: #000000;
  --dark-7: #151515;
  --light-1: #8d8d8f;
  --light-2: #edf2f4;
  --light-3: #f3f3f3;
  --light-4: #979797;
  --light-5: #ffffff;
  --yellow-shade: #f2c94c;
  --green-shade: #45bb4d;
  --blue-shade: #25aad3;
  --purple-shade: #564aac;
  --exclusive: #d4af37;
  --yellow-upper-area: 50%;
  --yellow-lower-area: var(--yellow-upper-area);
  --green-upper-area: 50%;
  --green-lower-area: var(--green-upper-area);
}

.date-picker-section {
  background-color: var(--bn-brand-color);
  /* margin-bottom: 54px; */
  margin-bottom: 28px;
}

.date-picker-section .date-picker-sect {
  /* padding: 28px 0; */
  padding: 8px 0;
}

.date-picker-section .config {
  width: 100%;
  height: 100%;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  /* cursor: pointer; */
  position: relative;
}

.date-picker-section .config .actual-text {
  cursor: pointer;
  display: inline-block;
}

.date-picker-section .config:nth-child(2) div {
  margin-left: auto;
  margin-right: auto;
}

.date-picker-section .config:last-child .title {
  margin-left: auto;
}

.date-picker-section .config .title {
  /* font-size: 20px; */
  font-size: 16px;
  font-weight: 700;
}

.config p:first-of-type {
  margin-left: 18px;
}

.config .md-icon {
  width: 24px;
  height: auto;
}

.date-picker-section .date-section {
  /* height: 62px;
  width: 62px; */
  height: 54px;
  width: 54px;
  border: 2px solid var(--light-5);
  color: var(--light-5);
  background-color: var(--bn-brand-color);
  border-radius: 8px;
  line-height: 1;
  padding: 4px 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s,
    transform 0.25s;
  margin-left: auto;
  margin-right: auto;
}

.date-picker-section .date-section:hover,
.date-picker-section .date-section.selected {
  color: var(--dark-4);
  background-color: var(--light-5);
}

.date-picker-section .date-section.inactive {
  /* color: var(--light-4);
  background-color: var(--dark-3);
  border-color: var(--dark-3); */
  cursor: default;
  pointer-events: none;
  border-color: var(--light-5);
  background-color: var(--bn-brand-color);
  color: var(--light-5);
  opacity: 0.4;
}

.date-picker-section .date-section:active {
  transform: scale(0.9);
}

.date-picker-section .date-section .month,
.date-picker-section .date-section .day {
  font-size: 8px;
  font-weight: 400;
}

.date-picker-section .date-section .date {
  /* font-size: 28px; */
  font-size: 18px;
  font-weight: 700;
  padding: 2px 0;
}

.new-date-picker {
  font-weight: 600;
  background-color: var(--dark-2);
  border-radius: 16px;
  border: 1px solid var(--light-5);
  position: absolute;
  top: 30px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: var(--bn-brand-color);
  color: var(--light-5);
}

.DayPicker-Day--today {
  color: var(--bn-brand-color);
}

/* .DayPicker-NavButton:hover {
  color: var(--bn-brand-color);
} */
/* nav-button uses background image */

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  color: var(--light-5);
  background-color: rgba(232, 48, 138, 0.25);
}

.DayPicker-Day--disabled {
  pointer-events: none;
  cursor: default;
  color: white;
}

.DayPicker-Caption > div {
}

.show-status {
  font-size: 16px;
  font-weight: 600;
  /* margin-bottom: 52px; */
  margin-bottom: 28px;
}

.play-trailer {
  cursor: pointer;
  transition: transform 0.25s, font-size 0.25s, height 0.25s, width 0.25s;
}

.play-trailer img {
  /* cursor: pointer; */
  transition: transform 0.25s;
}

.play-trailer:hover {
  transform: translateY(-2.5px);
}

/* .play-trailer:hover img {
  transform: scale(1.2);
}

.play-trailer:hover {
  font-size: 18px;
} */

/* -----------------movie-show--------------------------- */

.movie-show {
  /* margin-bottom: 64px; */
  margin-bottom: 54px;
}

.movie-show:not(:last-of-type) {
  border-bottom: 2px solid white;
}

.movie-show .theatre-name {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

.movie-show .theatre-address,
.movie-show .theatre-language,
.movie-show .hall-name {
  font-size: 16px;
  font-weight: 600;
}

.movie-show .hall-name,
.schedules .hall-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  border-radius: 50rem;
  background-color: var(--dark-4);
  color: var(--light-3);
  font-size: 14px;
  font-weight: 600;
  padding: 0 12px;
  height: 30px;
}

.movie-show .theatre-language,
.schedules .theatre-language {
  color: var(--bn-brand-color);
  text-align: right;
}

.movie-show .language-row {
  /* margin-bottom: 64px; */
  margin-bottom: 28px;
}

.movie-show .show-section {
  border-left: 2px solid white;
  padding-left: 15px;
  display: flex;
  flex-flow: row wrap;
}

.movie-show .show-section .show,
.schedules .show-btn {
  height: 48px;
  /*width: 95px;*/
  /* min-width: 95px; */
  min-width: 65px;
  width: auto;
  display: flex;
  /*justify-content: center;*/
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  border-radius: 6px;
  color: var(--dark-1);
  cursor: pointer;
  padding: 4px 6px 0;
  transition: background-color 0.5s, color 0.5s, border-radius 0.5s,
    border-color 0.5s, transform 0.25s;
}

.movie-show .show-section .show:not(:last-child) {
  margin-right: 18px;
}

.movie-show .show-section .show:active,
.schedules .show-btn:active {
  transform: scale(0.9);
}

.movie-show .show-section .show.yellow,
.schedules .show-btn.yellow {
  background-image: none;
  background-color: var(--yellow-shade);
  border: 1px solid var(--yellow-shade);
}

.movie-show .show-section .show.green,
.schedules .show-btn.green {
  background-image: none;
  background-color: var(--green-shade);
  border: 1px solid var(--green-shade);
}

.movie-show .show-section .show.full,
.schedules .show-btn.full {
  background-image: none;
  background-color: var(--dark-3);
  color: var(--light-4);
  border: 1px solid var(--dark-3);
  cursor: default;
  pointer-events: none;
}

.movie-show .show-section .show.yellow:hover,
.schedules .show-btn.yellow:hover {
  background-image: none;
  color: var(--yellow-shade);
  background-color: var(--dark-6);
  border: 1px solid var(--yellow-shade);
}

.movie-show .show-section .show.green:hover,
.schedules .show-btn.green:hover {
  background-image: none;
  color: var(--green-shade);
  background-color: var(--dark-6);
  border: 1px solid var(--green-shade);
}

.movie-show .show-section .show.inactive,
.schedules .show-btn.inactive {
  background-color: var(--dark-3);
  color: var(--light-3);
  border-color: var(--dark-3);
  cursor: default;
  pointer-events: none;
}

.movie-show .show-section .show .exp,
.schedules .show-btn .exp {
  min-width: 36px;
  width: auto;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background-color: var(--dark-1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 700;

  padding: 0 6px;
  position: relative;
}

.movie-show .show-section .show .exp .hover-text,
.schedules .show-btn .exp .hover-text {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 0;
  background: var(--dark-6);
  border: 3px solid var(--dark-6);
  opacity: 0;
  visibility: hidden;
  transition: height 0.5s, opacity 0.5s, visibility 0s;
  border-radius: 10px;
  /* display: none; */
  bottom: 110%;
}

.movie-show .show-section .show .exp .hover-text p,
.schedules .show-btn .exp .hover-text p {
  flex: 0 0 50%;
  font-size: 16px;
  font-weight: 600;
  padding: 0 24px;
  text-align: center;
  color: var(--light-5);
}

.movie-show .show-section .show .exp:hover .hover-text,
.schedules .show-btn .exp:hover .hover-text {
  width: 246px;
  height: 90px;
  opacity: 1;
  visibility: visible;
  display: flex;
}

.movie-show .show-section .show .exp:hover .hover-text::after,
.schedules .show-btn .exp:hover .hover-text::after {
  content: "";
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top: 20px solid var(--dark-6);
  position: absolute;
  top: 100%;
  right: 45%;
}

.movie-show .show-section .show .show-time,
.schedules .show-btn .show-time {
  font-size: 16px;
  font-weight: 600;
}

.movie-show .show-section .show .seats-booked,
.schedules .show-btn .seats-booked {
  font-size: 10px;
  font-weight: 600;
}

.movie-show .show-section .view-more {
  font-size: 16px;
  font-weight: 600;
  color: var(--light-5);
  flex: 1 0 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.movie-show .show-section .view-more::after {
  content: "";
  position: absolute;
  top: 35%;
  margin-left: 1em;
  vertical-align: middle;
  border-top: 0.6em solid;
  border-right: 0.6em solid transparent;
  border-bottom: 0;
  border-left: 0.6em solid transparent;
}

/* -----------------movie-show--------------------------- */

/* -----------------checkout--------------------------- */

.for-prev-step {
  position: relative;
}

.prev-step {
  position: absolute;
  /* top: 5%; */
  top: 0%;
  /* right: 90%; */
  left: 16px;
  z-index: 1;
}

.prev-step .blue-btn {
  min-width: 50px;
}

.steps {
  width: min(100%, 1000px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 54px;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.steps .step {
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background-color: white;
  /* cursor: pointer; */
  background-position: 50%;
  /* background-size: auto; */
  background-size: 50%;
  background-repeat: no-repeat;
}

/* .steps .step:hover, */
.steps .step.complete {
  background-color: var(--light-2);
}

.steps .step::before {
  content: "";
  display: block;
  width: 2000px;
  position: absolute;
  top: 50%;
  left: 100%;
  border-top: 1.35px solid white;
}

.steps .step.complete::before {
  border-top: 1.35px solid var(--light-5);
}

.steps .step.ticket {
  background-image: url("../imgs/svgs/ticket-red.svg");
}

/* .steps .step.ticket:hover, */
.steps .step.ticket.complete {
  background-image: url("../imgs/svgs/ticket-red.svg");
}

.steps .step.screen {
  background-image: url("../imgs/svgs/screen-step.svg");
}

/* .steps .step.screen:hover, */
.steps .step.screen.complete {
  background-image: url("../imgs/svgs/screen-red.svg");
}

.steps .step.seat {
  background-image: url("../imgs/svgs/seat-step.svg");
}

/* .steps .step.seat:hover, */
.steps .step.seat.complete {
  background-image: url("../imgs/svgs/seat-red.svg");
}

/* stage-2 */

.screening-parent {
  /* width: min(100%, 354px); */
  width: min(100%, 400px);
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.screening {
  width: 100%;
  height: 493px;
  text-align: center;
  line-height: 120%;
  display: flex;
  flex-flow: column nowrap;
  /* border-radius: -8px; */
}

.screening.basic,
.basic.screening::before,
.basic.screening::after,
.screening.basic .lower::before,
.screening.basic .lower::after {
  border: 4px solid var(--blue-shade);
  /* box-shadow: 0px 2px 14px rgba(37, 170, 211, 0.4); */
  transition: border-color 0.25s;
}

.screening.regular,
.regular.screening::before,
.regular.screening::after,
.screening.regular .lower::before,
.screening.regular .lower::after {
  border: 4px solid var(--green-shade);
  /* box-shadow: 0px 2px 14px rgba(69, 187, 77, 0.4); */
  transition: border-color 0.25s;
}

.screening.premium,
.premium.screening::before,
.premium.screening::after,
.screening.premium .lower::before,
.screening.premium .lower::after {
  border: 4px solid var(--yellow-shade);
  /* box-shadow: 0px 2px 14px rgba(242, 201, 76, 0.4); */
  transition: border-color 0.25s;
}

.screening.basic:active,
.basic.screening:active::before,
.basic.screening:active::after,
.screening.basic:active .lower::before,
.screening.basic:active .lower::after,
.screening.regular:active,
.regular.screening:active::before,
.regular.screening:active::after,
.screening.regular:active .lower::before,
.screening.regular:active .lower::after,
.screening.premium:active,
.premium.screening:active::before,
.premium.screening:active::after,
.screening.premium:active .lower::before,
.screening.premium:active .lower::after {
  border-color: var(--bn-brand-color);
}

.screening.basic.inactive,
.basic.screening.inactive::before,
.basic.screening.inactive::after,
.screening.basic.inactive .lower::before,
.screening.basic.inactive .lower::after,
.screening.regular.inactive,
.regular.screening.inactive::before,
.regular.screening.inactive::after,
.screening.regular.inactive .lower::before,
.screening.regular.inactive .lower::after,
.screening.premium.inactive,
.premium.screening.inactive::before,
.premium.screening.inactive::after,
.screening.premium.inactive .lower::before,
.screening.premium.inactive .lower::after {
  pointer-events: none;
}

.screening .upper,
.screening .lower {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
}

.screening .upper {
  /* padding: 40px 0; */
  flex: 52%;
  background-color: var(--dark-2);
}

.screening .icon-wrapper {
  position: initial;
}

.screening .screening-name,
.screening .price {
  font-size: 24px;
  font-weight: bold;
}

.screening .screening-name {
  /* margin-top: 32px; */
}

.screening .lower {
  /* padding: 24px 0 48px 0; */
  flex: 0 0 48%;
}

.screening::before,
.screening::after,
.screening .lower::before,
.screening .lower::after {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  background: var(--dark-1);
  border-radius: 50%;
  border: 4px solid var(--light-5);
}

.screening::before,
.screening::after {
  top: -20px;
}

.screening .lower::before,
.screening .lower::after {
  bottom: -20px;
}

.screening::before,
.screening .lower::before {
  right: -20px;
}

.screening::after,
.screening .lower::after {
  left: -20px;
}

.screening .description {
  font-size: 16px;
  font-weight: 600;
}

/* .screening .price {
  margin-top: 14px;
} */

/* .screening .blue-btn {
  margin-top: 24px;
} */

.screening.basic .blue-btn {
  background-color: var(--blue-shade);
  border-color: var(--blue-shade);
}

.screening.basic .blue-btn:hover {
  color: var(--blue-shade);
  background-color: var(--light-5);
}

.screening.regular .blue-btn {
  background-color: var(--green-shade);
  border-color: var(--green-shade);
}

.screening.regular .blue-btn:hover {
  color: var(--green-shade);
  background-color: var(--light-5);
}

.screening.premium .blue-btn {
  background-color: var(--yellow-shade);
  border-color: var(--yellow-shade);
}

.screening.premium .blue-btn:hover {
  color: var(--yellow-shade);
  background-color: var(--light-5);
}

.screening.basic .blue-btn.inactive,
.screening.regular .blue-btn.inactive,
.screening.premium .blue-btn.inactive {
  background-color: var(--dark-3);
  color: var(--light-4);
  border-color: transparent;
  cursor: default;
  pointer-events: none;
}

.screening.basic .blue-btn:active,
.screening.regular .blue-btn:active,
.screening.premium .blue-btn:active {
  background-color: var(--bn-brand-color);
  color: var(--light-5);
  border-color: var(--bn-brand-color);
}

/* --------------------screening type new--------------------- */

.screening-type-2 {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}

.screening-type-2 article {
  white-space: initial;
  /* display: inline-block; */
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 305px;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--dark-2);
  color: var(--light-5);
  border: 1px solid var(--bn-brand-color);
  border-top: 12px solid var(--bn-brand-color);
  /* padding: 14px 32px 44px 32px; */
  /* padding: 14px 24px 44px 24px; */
  padding: 14px 8px 44px 8px;
  border-radius: 4px;
  text-align: center;
  /* min-height: 470px; */
  margin: 0 8px;
}

.screening-type-2 article.green {
  border-color: var(--green-shade);
}

.screening-type-2 article.yellow {
  border-color: var(--exclusive);
}

.screening-type-2 article .popular {
  /* margin-bottom: 30px; */
  margin-bottom: 22px;
  color: white;
}

.screening-type-2 article .screen-name {
  /* font-size: 26px; */
  font-size: 22px;
  font-weight: 700;

  /* margin-bottom: 34px; */
  /* margin-bottom: 26px; */
}

.screening-type-2 article .around {
  color: white;
}

.screening-type-2 article .around-price {
  /* font-size: 64px; */
  /* font-size: 48px; */
  font-size: 42px;
  font-weight: 700;

  /* margin-bottom: 34px; */
  margin-bottom: 26px;
}

.screening-type-2 article .around-price {
  margin-bottom: 24px;
}

/* .screening-type-2 article p:nth-child(5) {
  margin-bottom: 44px;
} */

.screening-type-2 article .screen-desc {
  margin-bottom: 24px;
}

.screening-type-2 article.green .blue-btn {
  background-color: var(--green-shade);
  border-color: var(--green-shade);
}

.screening-type-2 article.green .blue-btn:hover,
.screening-type-2 article.green .blue-btn:focus {
  color: var(--green-shade);
  background-color: var(--light-5);
}

.screening-type-2 article.yellow .blue-btn {
  background-color: var(--exclusive);
  border-color: var(--exclusive);
}

.screening-type-2 article.yellow .blue-btn:hover,
.screening-type-2 article.yellow .blue-btn:focus {
  color: var(--exclusive);
  background-color: var(--light-5);
}

.screening-type-2 article .blue-btn.inactive {
  background-color: var(--dark-3) !important;
  color: var(--light-4) !important;
  border-color: var(--dark-3) !important;
  cursor: default;
  pointer-events: none;
}

.screening-type-2 article .audi-cap {
  font-size: 12px;
  font-weight: 600;
}

/* --------------------------------screening type new------------------------------- */

/* Stage 3*/

.seat-selection-heading p {
  position: relative;
  height: 40px;
  min-width: 184px;
  background: rgba(51, 51, 51, 0.99);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 34px;
  font-size: 16px;
  font-weight: 700;
}

.seat-selection-heading p::before,
.seat-selection-heading p::after {
  content: "";
  border-top: 1.5px solid var(--dark-3);
  width: 44px;
  position: absolute;
  top: 50%;
}

.seat-selection-heading p::before {
  right: calc(100% + 14px);
}

.seat-selection-heading p::after {
  left: calc(100% + 14px);
}

.seat-selection-row {
  /* display: flex; */
  display: block;
  text-align: center;
  overflow-x: auto;
  white-space: nowrap;
  justify-content: center;
}

.seat-selection-row .seat-selection-btn {
  user-select: none;
  background: var(--dark-3);
  border-radius: 6px;
  font-weight: bold;

  font-size: 20px;
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.5s, background-color 0.5s, border-color 0.5s,
    transform 0.25s;
}

.seat-selection-row .seat-selection-btn:not(:last-of-type) {
  margin-right: 14px;
}

.seat-selection-row .seat-selection-btn:hover,
.seat-selection-row .seat-selection-btn.selected {
  background: var(--green-shade);
}

.seat-selection-row .seat-selection-btn:active {
  transform: scale(0.9);
}

.seat-selection-row .seat-selection-btn.inactive {
  color: var(--light-4);
  cursor: default;
  pointer-events: none;
}

.seat-selection-row .list-inline-item:not(:last-child) {
  margin-right: 14px;
}

.seat-layout-screen {
  margin-bottom: 64px;
  position: relative;
}

.seat-layout-screen span {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 0);
}

.table-responsive.table-width {
  width: 90%;
}

.master-table {
  width: auto !important;
  /* margin: 0 auto 44px auto; */
  margin: 0 auto 24px auto;
  color: var(--light-5);
  text-align: center;
  /* transform: translate(-125px); */
  /*temporary class*/
  border-collapse: separate;
  border-spacing: 0 8px;
}

.master-table td {
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

/* .master-table .actual-table {
  border-bottom: 1px solid white;
} */

/* .master-table .category:not(:last-of-type) {
  padding-bottom: 14px;
} */

.master-table .category-price {
  padding-right: 15px;
}

.master-table .category-price p {
  font-size: 16px;
  font-weight: 700;
}

.master-table .category-range {
  border-left: 1px solid white;
  position: relative;
  /*padding-right: 147px;*/
  /* padding-right: 10vw; */
  padding-right: 130px;
}

.master-table .category-range::before,
.master-table .category-range::after {
  content: "";
  /*width: 65px;*/
  /*width: 5vw;*/
  width: min(5vw, 50px);
  left: 0;
  position: absolute;
  border-top: 1px solid white;
}

.master-table .category-range::before {
  top: 0;
}

.master-table .category-range::after {
  bottom: 0;
}

.master-table .cell {
  /*height: 28px;*/
  /*width: 28px;*/
  /* height: 35px; */
  /* width: 35px; */
  /* height: 40px; */
  height: 44px;
  /* width: 40px; */
  width: 44px;
  /* padding: 4px; */
  padding: 4px;
  font-size: 12px;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.master-table .cell.seat,
.master-table .cell.lounger,
.master-table .cell.wheel-chair,
.master-table .cell.covid-blocked {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  /*background-size: auto 20px;*/
  /* background-size: auto 27px; */
  cursor: pointer;
  transition: transform 0.4s;
}

.master-table .cell.seat {
  background-size: 32px auto;
  background-image: url("../imgs/svgs/seat-standard.svg");
}

.master-table .cell.seat.unavailable {
  cursor: default;
  pointer-events: none;
  background-image: url("../imgs/svgs/seat-standard-unavailable.svg");
}

.master-table .cell.seat.selected,
.master-table .cell.seat:not(.unavailable):not(.covid-blocked):hover,
.master-table .cell.seat:not(.unavailable):active {
  background-image: url("../imgs/svgs/seat-standard-selected.svg");
  transform: scale(1.2);
}

.master-table .cell.lounger {
  background-size: 50px auto;
  background-image: url("../imgs/svgs/seat-lounger.svg");
  /*width: 42px;*/
  /* width: 52px; */
  width: 58px;
}

.master-table .cell.lounger.unavailable {
  cursor: default;
  pointer-events: none;
  background-image: url("../imgs/svgs/seat-lounger-unavailable.svg");
}

.master-table .cell.lounger.selected,
.master-table .cell.lounger:not(.unavailable):hover,
.master-table .cell.lounger:not(.unavailable):active {
  background-image: url("../imgs/svgs/seat-lounger-selected.svg");
  transform: scale(1.2);
}

.master-table .cell.wheel-chair {
  background-size: 25px auto;
  background-image: url("../imgs/svgs/seat-wheel-chair.svg");
}

.master-table .cell.wheel-chair.unavailable {
  cursor: default;
  pointer-events: none;
  background-image: url("../imgs/svgs/seat-wheel-chair-unavailable.svg");
}

.master-table .cell.wheel-chair.selected,
.master-table .cell.wheel-chair:not(.unavailable):hover,
.master-table .cell.wheel-chair:not(.unavailable):active {
  background-image: url("../imgs/svgs/seat-wheel-chair-selected.svg");
  transform: scale(1.2);
}

.master-table .cell.covid-blocked {
  background-size: 26px auto;
  background-image: url("../imgs/svgs/seat-covid-blocked.svg");
  cursor: no-drop;
  pointer-events: none;
}

.master-table .seat-type {
  /* transform: translateX(20%); */
  /* margin-bottom: 24px; */
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.master-table .seat-type p {
  height: 30px;
  background: rgba(51, 51, 51, 0.99);
  border-radius: 20px;
  /* color: white; */
  color: var(--light-5);
  font-size: 16px;
  font-weight: 600;
  padding: 0 14px;
  min-width: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.master-table .seat-type p img,
.movie-show .theatre-address p img {
  margin-left: 8px;
}

.seat-type .hover-image-span,
.movie-show .theatre-address .hover-image-span {
  position: relative;
}

.seat-type .seat-type-image,
.movie-show .theatre-address .seat-type-image {
  position: absolute;
  /*bottom: 115%;*/
  /* width: 40%; */
  /*right: max(-10%, -100px);*/
  bottom: 30px;
  left: -148px;
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  background: var(--dark-1);
  border: 1px solid var(--light-5);
  border-radius: 10px;
  padding: 14px;
  z-index: 100;
  transition: visibility 0s, opacity 0.5s, height 0.5s;
}

.seat-type .seat-type-image::after,
.movie-show .theatre-address .seat-type-image::after {
  content: "";
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top: 20px solid var(--light-5);
  position: absolute;
  top: 100%;
  right: 47%;
}

.seat-type .seat-type-image img,
.movie-show .theatre-address .seat-type-image img {
  width: 309px;
  height: auto;
  max-height: 309px;
}

.seat-layout-icons {
  display: flex;
  margin: 0 auto 54px auto;
}

.seat-layout-icons img {
  height: 24px;
  width: auto;
}

.seat-layout-icons p {
  font-size: 16px;
  font-weight: 700;
}

.seat-layout-icons p:not(:last-of-type) {
  margin-right: 34px;
}

.movie-footer-space {
  margin-bottom: 180px;
}

.movie-footer {
  background: var(--bn-brand-color);
  margin-top: 44px;
  /* position: sticky; */
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
}

.movie-footer .sect {
  display: flex;
  width: 100%;
  /* padding: 24px 0; */
  padding: 8px 0;
  /* align-items: center; */
  line-height: 120%;
  flex-wrap: wrap;
}

.movie-footer .movie-img-n-details {
  display: flex;
  align-items: center;
}

.movie-footer .img-wrapper {
  /* width: max(117px, 20%); */
  /* width: max(80px, 16%); */
  /* width: max(60px, 12%); */
  width: 100px;
  flex: 0 0 auto;
  height: auto;
  margin-right: 16px;
}

.movie-footer .img-wrapper img {
  border-radius: 7px;
  width: 100%;
  height: auto;
}

.movie-footer .img-wrapper + div {
  /* flex-basis: auto; */
  flex-basis: 100%;
}

.movie-footer .movie-title {
  /* font-size: 20px; */
  font-size: 18px;
  font-weight: 700;

  /* margin-bottom: 12px; */
  margin-bottom: 8px;
}

.movie-footer .movie-details {
  /* font-size: 18px; */
  font-size: 16px;
  font-weight: 600;
}

.movie-footer .movie-details {
  /* margin-bottom: 12px; */
  margin-bottom: 8px;
}

.movie-footer .movie-field {
  /* font-size: 16px; */
  font-size: 14px;
  font-weight: 700;
}

.movie-footer .movie-field {
  /* margin-bottom: 8px; */
  margin-bottom: 4px;
}

.movie-footer .movie-field .coma:not(:last-of-type)::after {
  content: ", ";
}

.movie-footer .movie-field-title {
  /* font-size: 14px; */
  font-size: 12px;
  font-weight: 600;
}

.movie-footer .movie-field-title {
  /* margin-bottom: 8px; */
  /* margin-bottom: 4px; */
}

.movie-footer .total-price {
  font-size: 24px;
  font-weight: 700;
}

/* .movie-footer > div:not(:first-child) {
  padding-top: 16px;
  padding-bottom: 16px;
  border-left: 2px solid var(--light-5);
} */

.movie-footer .sect > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.movie-footer .sect > div:not(:last-child) {
  border-right: 2px solid var(--light-5);
}

.movie-footer div > p:last-child {
  margin-bottom: 0;
}

.blue-btn.footer {
  border: 2px solid var(--light-5);
}

.blue-btn.footer.inactive {
  border-color: var(--light-5);
  background-color: var(--bn-brand-color);
  color: var(--light-5);
  opacity: 0.4;
}

.movie-footer .for-mft {
  position: relative;
  min-height: 38px;
}

.footer-toggle {
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  right: 15px;
  top: 0;
}

.footer-toggle figure {
  transform: rotate(180deg);
  transition: transform 0.5s;
  border-radius: 50%;
  border: 1px solid var(--light-5);
  padding: 4px 7px;
  margin: 0;
}

.footer-toggle figure.ft-rotate {
  transform: rotate(0);
}

/* -----------------checkout--------------------------- */

/* -----------------cinema listing--------------------------- */

.cinema-hero-section .hero-text > div {
  position: relative;
  /* top: 40%; */
}

.cinema-hero-section .title {
  /* font-size: 74px; */
  font-size: 32px;
  font-weight: 700;

  line-height: 1.2;
  margin-bottom: 8px;
}

.cinema-hero-section .msg {
  /* font-size: 18px; */
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 88px;
}

.cinema-card {
  margin-bottom: 55px;
  cursor: pointer;
  transition: transform 0.5s;
}

.cinema-card:hover {
  /* transform: translateY(-10px); */
  transform: scale(1.025);
}

.cinema-card .img-wrapper {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
}

.cinema-card .img-wrapper img {
  width: 100%;
  height: auto;
}

.cinema-card .detail-row {
  display: flex;
  justify-content: space-between;
}

.cinema-card .detail-row:not(:last-of-type) {
  margin-bottom: 8px;
}

.cinema-card .cinema-name {
  font-size: 24px;
  font-weight: 700;

  line-height: 1.2;
  flex: 0 0 75%;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cinema-card .cinema-address,
.cinema-card .cinema-feature {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

.cinema-card .cinema-address {
  color: white;
  flex: 0 0 75%;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cinema-card .cinema-feature {
  color: var(--bn-brand-color);
}

.cinema-card .audi-seats {
  font-size: 16px;

  display: flex;
  align-items: center;
}

/* -----------------cinema-listing--------------------------- */

.pno .offer {
  background-color: var(--dark-2);
  width: 100%;
}

.pno .offer .img-wrapper {
  width: 100%;
  height: auto;
}

.pno .offer .img-wrapper img {
  width: 100%;
  height: auto;
}

.pno .offer .offer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 44px; */
  height: 100%;
  width: 100%;
}

.pno .offer .offer-name,
.pno .offer-tnc .a {
  font-size: 28px;
  font-weight: 700;

  letter-spacing: -0.5px;
}

.pno .offer .offer-name {
  margin-bottom: 24px;
}

.pno .offer .offer-desc,
.pno .offer-tnc .b {
  font-size: 18px;
  font-weight: 600;
}

.pno .offer .offer-desc {
  margin-bottom: 34px;
}

.pno .offer .btn {
  padding: 0 !important;
}

.pno .offer .btn-link:hover {
  text-decoration: none !important;
  color: unset !important;
}

.pno .accordion .card {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: unset !important;
  margin-bottom: 54px;
}

.pno .accordion .card-header {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: unset !important;
}

.pno .accordion .card-body {
  padding: 0 !important;
  padding-top: 34px !important;
  border-top: 1px solid white !important;
  margin-top: 24px;
}

.pno .offer-tnc {
  background-color: var(--dark-2);
  padding: 30px;
}

.pno .offer-tnc .a {
  margin-bottom: 14px;
}

.pno .offer-tnc .b {
  margin-bottom: 8px;
}

.pno .offer-tnc ul {
  margin-bottom: 0;
  padding-inline-start: 0;
  list-style: none;
}

.pno .offer-tnc ul li::before {
  content: "";
  margin-right: 14px;
  font-weight: bold;

  width: 8px;
  height: 8px;
  background-color: var(--bn-brand-color);
  display: inline-block;
  border-radius: 50%;
}

/* -----------------FAQs--------------------------- */

/* -----------------How it works--------------------------- */

.presentation:first-of-type {
  /* margin-top: 114px; */
  margin-top: 64px;
}

.presentation:not(:last-of-type) {
  /* margin-bottom: 204px; */
  margin-bottom: 64px;
}

.presentation:last-of-type {
  /* margin-bottom: 144px; */
  margin-bottom: 64px;
}

.presentation .title {
  font-size: 44px;
  font-weight: 700;

  margin-bottom: 12px;
}

.presentation .msg {
  font-size: 16px;
  font-weight: 600;
}

/* -----------------How it works--------------------------- */

/* ---------------------Fnb--------------------------- */

.fnb-row {
  /* margin-top: 96px; */
  margin-top: 52px;
}

.fnb-card {
  /* margin-bottom: 64px; */
  margin-bottom: clamp(16px, 1.8vw, 32px);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.fnb-card .img-wrapper {
  width: 100%;
  height: auto;
  margin: 0;
}

.fnb-card .img-wrapper img {
  width: 100%;
  height: auto;
}

.fnb-card .fnb-content-row {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.fnb-card > article {
  background-color: var(--dark-2);
  /* padding: 24px 24px 38px 24px; */
  padding: clamp(8px, 0.8vw, 16px);
}

.fnb-card .fnb-content-row:first-of-type {
  margin-bottom: 8px;
  align-items: center;
}

.fnb-card .fnb-content-row:first-of-type p {
  /* font-size: 24px; */
  font-size: clamp(12px, 0.8vw, 16px);
  font-weight: 700;
}

.fnb-card .fnb-content-row:first-of-type p:nth-child(1) {
  flex: 0 0 66%;
  padding-right: 12px;
}

.fnb-card .fnb-content-row:first-of-type p:nth-child(2) {
  flex: 0 0 34%;
  text-align: right;
}

.fnb-card .blue-btn {
  min-width: 101px;
}

/* .fnb-card .fnb-content-row:last-of-type aside:nth-child(1) {
  flex: 0 0 50%;
} */

.fnb-card .fnb-content-row:last-of-type {
  justify-content: flex-end;
}

.fnb-card .fnb-content-row:last-of-type .modifier-btn {
  /* flex: 0 0 50%; */
  /* flex: 0 0 100%; */
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fnb-card
  .fnb-content-row:last-of-type
  .modifier-btn
  span:nth-child(odd):not(.inactive) {
  cursor: pointer;
}

.fnb-card
  .fnb-content-row:last-of-type
  .modifier-btn
  span:nth-child(odd):not(.inactive):hover {
  transform: scale(1.15);
}

.fnb-card
  .fnb-content-row:last-of-type
  .modifier-btn
  span:nth-child(odd):not(.inactive):active {
  transform: scale(0.95);
}

.fnb-card .fnb-content-row:last-of-type .modifier-btn span {
  /* flex: 0 0 33%; */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.fnb-card .fnb-content-row:last-of-type .modifier-btn > span:nth-child(odd) {
  /* background: url("../imgs/svgs/fnb-decrease.svg") no-repeat 50% 50%; */
  font-size: 20px;
  border: 2px solid white;
  border-radius: 50%;
  color: var(--light-5);
  height: 28px;
  width: 28px;
  flex: 0 0 28px;
  box-sizing: content-box;
  line-height: 1;
  transition: transform 0.5s, background-color 0.5s;
}

.fnb-card
  .fnb-content-row:last-of-type
  .modifier-btn
  > span:nth-child(odd):hover,
.fnb-card .fnb-content-row:last-of-type .modifier-btn span:nth-child(1):active {
  /* background: url("../imgs/svgs/fnb-decrease-active.svg") no-repeat 50% 50%; */
  color: var(--bn-brand-color);
  border-color: var(--bn-brand-color);
}

.fnb-card .fnb-content-row:last-of-type .modifier-btn > span:nth-child(even) {
  margin: 0 8px;
  width: 2ch;
}

.fnb-card
  .fnb-content-row:last-of-type
  .modifier-btn
  > span:nth-child(odd).inactive {
  /* background: url("../imgs/svgs/fnb-decrease-inactive.svg") no-repeat 50%
    50%; */
  color: var(--dark-3);
  border-color: var(--dark-3);
  pointer-events: none;
  cursor: default;
}

/* .fnb-card .fnb-content-row:last-of-type .modifier-btn span:nth-child(3) {
  background: url("../imgs/svgs/fnb-increase.svg") no-repeat 50% 50%;
}

.fnb-card .fnb-content-row:last-of-type .modifier-btn span:nth-child(3):hover,
.fnb-card .fnb-content-row:last-of-type .modifier-btn span:nth-child(3):active {
  background: url("../imgs/svgs/fnb-increase-active.svg") no-repeat 50% 50%;
}

.fnb-card
  .fnb-content-row:last-of-type
  .modifier-btn
  span:nth-child(3).inactive {
  background: url("../imgs/svgs/fnb-increase-inactive.svg") no-repeat 50%
    50%;
} */

.fnb-card > aside {
  position: absolute;
  top: 0;
  left: 0;
}

.fnb-card > aside > p {
  height: clamp(18px, 2vw, 34px);
  min-width: clamp(50px, 4vw, 84px);
  font-size: clamp(10px, 0.8vw, 16px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
}

.fnb-card > aside > p:first-child {
  color: var(--light-5);
  background-color: var(--bn-brand-color);
  font-weight: 600;
  margin-top: clamp(6px, 0.6vw, 14px);
  margin-bottom: 4px;
}

.fnb-card > aside > p:last-child {
  background-color: var(--light-5);
  color: var(--dark-1);
  font-weight: 700;

  text-decoration: line-through;
}

.fnb-categories {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto;
  padding: 4px 0;
}

.fnb-category-btn,
.schedules-btn {
  height: 32px;
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--dark-2);
  color: var(--light-5);
  border-radius: 8px;
  border: 1px solid var(--bn-brand-color);
  padding: 0 12px;
  margin-right: clamp(8px, 1vw, 16px);
  transition: background-color 0.5s, color 0.5s, transform 0.25s;
}

.fnb-category-btn:focus,
.schedules-btn:focus {
  outline: none;
}

.fnb-category-btn:hover,
.fnb-category-btn:active,
.fnb-category-btn.selected,
.schedules-btn:hover,
.schedules-btn:active,
.schedules-btn.selected {
  background-color: var(--bn-brand-color);
  color: var(--light-5);
}

.fnb-category-btn:active,
.schedules-btn:active {
  transform: scale(0.9);
}

.fnb-order-details {
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-weight: 600;
  background-color: var(--dark-2);
  border-radius: 16px;
}

.fnb-order-details .title {
  font-size: clamp(16px, 1.5vw, 24px);

  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-4);
  margin-bottom: 8px;
  text-align: center;
}

.fnb-order-details .fnb-item-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.fnb-item-details > p:first-child {
  font-size: 16px;
  padding-right: 4px;
}

.fnb-item-details > p:first-child span,
.fnb-item-details > p:first-child p {
  color: var(--light-5);
  font-size: calc(0.6em);
}

.fnb-item-details > p:last-child {
  font-size: 16px;
  white-space: nowrap;
  padding-left: 4px;
}

.fnb-total {
  border-top: 1px solid var(--light-5);
  margin-top: 8px;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fnb-total p:first-child {
  font-size: 16px;
}

.fnb-total p:last-child {
  font-size: 18px;
}

.fnb-search {
  position: relative;
  width: 100%;
}

.fnb-search input {
  height: 40px;
  width: 100%;
  border: none;
  background-color: var(--dark-2);
  padding: 0 16px 0 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  color: var(--light-5);
  outline: none;
}

.fnb-search img {
  position: absolute;
  left: 10px;
  top: 10px;
}

.fnb-items {
  max-height: 45vh;
  overflow-y: auto;
  margin-bottom: 32px;
}

/* ---------------------Fnb--------------------------- */

/* ---------------------Schedules--------------------------- */

.schedules {
  font-weight: 600;
}

.schedules .cinema-name {
  font-size: clamp(24px, 1.5vw, 32px);
  margin-bottom: 0;
}

.schedules .img-wrapper {
  margin: 0;
}

.schedules .img-wrapper img {
  width: 100%;
}

.schedules .schedules-row {
  font-size: 12px;
}

.schedules .schedules-row table {
  margin-bottom: 8px;
}

.schedules .schedules-row tr > td:nth-child(1) {
  color: var(--bn-brand-color);
}

.schedules .schedules-row tr > td:nth-child(2) {
  padding-left: 8px;
}

.schedules .schedules-row .play-trailer img {
  width: 24px;
}

.schedules .schedules-row:not(:last-child) {
  margin-bottom: clamp(24px, 1.6vw, 32px);
}

.schedules .schedules-row .blue-btn {
  height: 30px;
  font-size: 12px;
  padding: 0 12px;
  min-width: 70px;
}

.schedules .schedules-row .movie-title {
  font-size: clamp(18px, 1.1vw, 24px);

  margin-bottom: 8px;
}

.schedules .schedules-row .schedules-section {
  overflow-x: auto;
  margin: 0 auto;
}

.schedules .schedule-grid {
  display: grid;
  min-width: 100%;
  grid-template-columns: repeat(9, minmax(62px, 1fr));
  margin-bottom: 8px;
  font-size: 12px;
  text-align: center;
}

.schedule-grid > section {
  padding: 0 4px;
}

.schedule-grid.date-n-day > section:not(:first-child, :nth-child(2)),
.schedule-grid.schedule > section:not(:first-child) {
  border-left: 1px solid var(--light-4);
  text-align: center;
}

.schedule-grid.date-n-day > section > p:first-child {
  margin-bottom: 4px;
}

.schedule-grid.schedule .screen-name {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: 26px;
  border-radius: 50rem;
  border: 1px solid var(--dark-2);
  background-color: var(--light-5);
  color: var(--dark-2);
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  cursor: pointer;
  padding: 0 8px;
  transition: background-color 0.5s, color 0.5s, transform 0.25s,
    border-color 0.5s;
}

.schedules .hall-name {
  font-size: 12px;
  height: 26px;
  padding: 0 8px;
}

.schedules .theatre-language {
  font-size: 12px;
  margin-top: 6px;
  text-align: left;
}

.schedules .show-btn {
  display: inline-flex;
  height: 30px;
  min-width: 40px;
  margin-bottom: 0;
  padding: 0 6px 0 8px;
}

.schedules section > .show-btn:not(:last-child) {
  margin-bottom: 8px;
}

.schedules .show-btn .show-time {
  font-size: 12px;
}

.schedules .show-btn .exp {
  min-width: 24px;
  height: 24px;
  margin-left: 6px;
  font-size: 10px;
  padding: 0 6px;
}

.big-heading {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 44px;
}

/* .schedule-grid.schedule .screen-name:hover,
.schedule-grid.schedule .screen-name:active {
  background-color: var(--dark-2);
  color: var(--light-5);
  border-color: var(--light-5);
}

.schedule-grid.schedule .screen-name:active {
  transform: scale(0.9);
} */

/* .schedules .show-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 26px;
  border-radius: 50rem;
  --show-btn-theme: var(--bn-brand-color);
  border: 1px solid var(--show-btn-theme);
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  color: var(--light-5);
  margin: 0 auto;
  margin-bottom: 8px;
  cursor: pointer;
  transition: color 0.5s, transform 0.25s;
  outline: none;
}

.schedules .show-btn:active {
  transform: scale(0.9);
}

.schedules .show-btn:hover,
.schedules .show-btn:active {
  color: var(--show-btn-theme);
}

.schedules .show-btn.green {
  --show-btn-theme: var(--green-shade);
}

.schedules .show-btn.yellow {
  --show-btn-theme: var(--yellow-shade);
} */

/* ---------------------Schedules--------------------------- */

/* -----------------slick slider overrides'--------------------------- */

.movie-slider .slick-prev::after,
.movie-slider .slick-next::after,
.movie-slider .slick-prev:before,
.movie-slider .slick-next:before,
.date-slider-2 .slick-prev::after,
.date-slider-2 .slick-next::after,
.date-slider-2 .slick-prev:before,
.date-slider-2 .slick-next:before,
.pno-slider .slick-prev::after,
.pno-slider .slick-next::after,
.pno-slider .slick-prev:before,
.pno-slider .slick-next:before,
.dots-1 .slick-prev::after,
.dots-1 .slick-next::after,
.dots-1 .slick-prev:before,
.dots-1 .slick-next:before {
  content: "";
  border: none;
  width: 0;
  height: 0;
}

.date-slider-2 .slick-prev {
  background: url("../imgs/svgs/date-left-lg.svg") no-repeat 50% 50% !important;
  /* left: max(-10%, -50px); */
  left: -45px;
  border: none;
  top: 55%;
  /* width: 20px; */
  /* width: 40px;
  height: auto; */
}

.date-slider-2 .slick-next {
  background: url("../imgs/svgs/date-right-lg.svg") no-repeat 50% 50% !important;
  /* right: max(-7%, -35px); */
  right: -45px;
  border: none;
  top: 55%;
  /* width: 20px; */
  /* width: 40px;
  height: auto; */
}

/* -----------------slick slider overrides'--------------------------- */

/* SEAT DISABLED */
.seat-disabled {
  opacity: 0.2;
  pointer-events: none;
}

/* -----------------slick slider overrides'--------------------------- */

/* -----------------media queries--------------------------- */

@media (min-width: 767px) and (max-width: 1200px) {
  .fests-n-events .value {
    font-size: 22px;
  }

  .fests-n-events .name {
    font-size: 22px;
    margin-bottom: 18px;
  }

  .big-heading {
    font-size: 40px;
    /* margin-bottom: 68px; */
    margin-bottom: 44px;
  }

  .movie-section .title-row .name,
  .movie-slider-heading span {
    /* font-size: 32px; */
    font-size: 26px;
    margin-right: 18px;
  }

  .hero-section .hero-text .movie-features div {
    /* margin-bottom: 16px; */
  }

  .movie-slider-heading {
    margin-top: 100px;
  }

  footer .actual-footer {
    padding-top: 44px;
    /* margin-top: 120px; */
    margin-top: 44px;
  }

  .pno-slider {
    /* margin-top: 120px; */
    margin-top: 44px;
  }

  .movie-section {
    /* margin-top: 75px;
    margin-bottom: 120px; */
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .movie-section #ms-btn {
    margin-top: 44px;
  }

  .movie-card {
    /* margin-top: 44px; */
    margin-top: 32px;
  }

  .movie-show {
    margin-bottom: 44px;
  }

  .cinema-details .tags span {
    font-size: 16px;
    padding: 7px 16px;
    height: 30px;
    min-width: 100px;
    letter-spacing: 1px;
    margin-bottom: 14px;
  }

  .cinema-details .tags span:not(:last-child) {
    margin-right: 10px;
  }

  .hero-section .hero-img-section .hero-img-1,
  .cinema-hero-section .hero-img-section .hero-img-1 {
    min-height: 200px;
  }

  .movie-show .pbm {
    padding-bottom: 24px;
    border-bottom: 2px solid white;
    margin-bottom: 24px;
  }

  .movie-container {
    align-items: center;
  }

  .seat-selection-row .seat-selection-btn {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .mobile-search-bar .searched-movie .movie-content .movie-name {
    margin-top: 8px;
  }

  .movie-container > .mi {
    width: 38.5%;
  }

  .movie-container > .md {
    width: 61.5%;
  }

  .new-date-picker {
    left: 0;
    transform: translateX(0);
  }
}

/* -----------------media queries--------------------------- */

/* Keep your css above media queries */

.bg-ticket {
  background: var(--bn-brand-color);
  color: var(--light-5);
}

.email-success-message {
  background: var(--green-shade);
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.green-text {
  color: var(--green-shade) !important;
}

.booking-success-accordian button::after {
  display: none;
}

.show-password-icon {
  top: 35px;
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.show-password-icon-2 {
  top: 14px;
}

.show-password-icon-3 {
  top: 40px;
}

.ticket-type-grid {
  display: grid;
  padding: 0px 0 10px 0;
}
.ticket-type-col {
  margin-top: 20px;
  /* border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 10px 0; */
}
.p-heading {
  color: white;
  font-size: 16px !important;
  letter-spacing: 0.3px !important;
}
.grid-col-5 {
  display: grid;
  grid-template-columns: 42% 35%;
}

.cursor-pointer {
  cursor: pointer;
}

/* --------------------style.css---------------------- */

/*********----------------------POPUPS------------------------********/

.show-details-popup .wrapper,
.seat-type-popup .wrapper,
.register-popup .wrapper,
.login-popup .wrapper,
.otp-popup .wrapper,
.register-complete-popup .wrapper,
.seat-layout-popup .wrapper {
  position: relative;
  color: var(--light-5);
  background-color: var(--dark-1);
  padding: 44px;
  border-radius: 20px;
  border: 1px solid var(--light-5);
}

.show-details-popup .close-btn-main,
.seat-type-popup .close-btn-main,
.register-popup .close-btn-main,
.seat-layout-popup .close-btn-main {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  z-index: 1;
}

.show-details-popup .modal-content,
.seat-type-popup .modal-content,
.register-popup .modal-content,
.login-popup .modal-content,
.otp-popup .modal-content,
.register-complete-popup .modal-content,
.share-ticket-popup .modal-content,
.seat-layout-popup .modal-content {
  background-color: transparent;
}

.show-details-popup .poster-img {
  width: 100%;
  height: auto;
  margin-bottom: 34px;
}

.show-details-popup .poster-img img {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.show-details-popup .heading {
  /*color: var(--dark-4);*/
  font-size: 24px;
  font-weight: 700;

  margin-bottom: 14px;
}

.show-details-popup .sub-heading {
  /*color: var(--dark-4); */
  font-size: 18px;
  font-weight: 700;

  padding-bottom: 10px;
  margin-bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dcdcde;
}

.show-details-popup .tags {
  margin: 15px 0 05px 0;
}

.show-details-popup .tags span {
  font-size: 14px;
  /* padding: 7px 26px; */
  height: 30px;
  min-width: 100px;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 14px 0;
  border: 1px solid var(--bn-brand-color);
  border-radius: 8px;
  padding: 0 12px;
}

.show-details-popup .features .features-part {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}

.show-details-popup .features .features-part span {
  padding-left: 10px;
}

.show-details-popup .features .features-part > div {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.show-details-popup .btn-main-group {
  /*margin-bottom: 24px;*/
  margin-top: 14px;
  /* display: flex;
  flex-flow: row wrap; */
  display: grid;
  grid-gap: 0 24px;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
}

.show-details-popup .btn-main-group .tile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcde;
  border-radius: 7px;
  cursor: pointer;
  background: transparent;
  /* min-width: 124px; */
  min-width: 118px;
  transition: color 0.5s, background-color 0.4s, border-color 0.4s,
    transform 0.25s;
  font-size: 16px;
  font-weight: 600;
  color: var(--light-5);
  height: 40px;
  outline: 0;
  padding: 0 10px;
}

.show-details-popup .btn-main-group .tile.format {
  min-width: 60px;
}

.show-details-popup .btn-main-group .tile:hover,
.show-details-popup .btn-main-group .tile.active {
  background: var(--bn-brand-color);
  outline: 0;
  border: 1px solid var(--bn-brand-color);
  color: var(--light-5);
}

.show-details-popup .btn-main-group .tile:active {
  transform: scale(0.95);
}

.show-details-popup .btn-main-group .tile {
  margin-bottom: 24px;
}

/* .show-details-popup .btn-main-group .tile:not(:last-of-type) {
  margin-right: 14px;
} */

.seat-type-popup .title-row {
  margin-bottom: 37px;
}

.seat-type-popup .seat-type-cat {
  background-color: var(--bn-brand-color);
  padding-left: 2px;

  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 700;
  /* text-decoration: underline; */
}

.seat-type-popup .title-row .title {
  font-size: 24px;
  font-weight: 700;
}

.seat-type-popup .selected-seats {
  min-width: 214px;
  height: 40px;
  background: var(--green-shade);
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* font-size: 16px; */
  font-size: 24px;
  font-weight: 700;

  padding: 0 2rem;
}

.seat-type-popup .seat-picker {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  font-size: 18px;
  font-weight: 700;
}

.seat-type-popup .seat-picker .seat-picker-row {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 36% 36% 24%;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #dcdcde;
  margin-bottom: 24px;
  grid-gap: 2%;
}

.seat-type-popup .seat-picker .seat-picker-row.headings {
  padding-bottom: 10px;
}

.seat-type-popup .seat-picker .seat-picker-row.total {
  font-size: 24px;
}

.seat-type-popup .seat-picker .seat-picker-row.total div:first-of-type {
  grid-column: 1/3;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier {
  display: flex;
  justify-content: space-between;
  width: min(151px, 100%);
  height: 32px;
  background: var(--dark-3);
  border-radius: 5px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 700;

  user-select: none;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(n) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(odd) {
  width: 32px;
  height: 100%;
  background: var(--light-5);
  color: var(--dark-3);
  cursor: pointer;
  font-size: 30px;
  font-weight: 400;
}

.seat-type-popup
  .seat-picker
  .seat-picker-row
  .modifier
  span:nth-child(odd):hover {
  /*color: var(--light-5);*/
  /*background-color: var(--dark-3);*/
  color: var(--bn-brand-color);
}

.seat-type-popup
  .seat-picker
  .seat-picker-row
  .modifier
  span:nth-child(odd).inactive {
  color: var(--dark-3);
  background-color: var(--light-4);
}

.seat-type-popup
  .seat-picker
  .seat-picker-row
  .modifier
  span:nth-child(even).inactive {
  color: var(--light-4);
}

.seat-type-popup .question {
  font-size: 24px;
  font-weight: 700;

  margin-bottom: 18px;
}

.seat-type-popup .answer {
  width: 100%;
  height: 45px;
  color: white;
  background-color: transparent;
  border: 1px solid #dcdcde;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 12px;
  margin-bottom: 24px;
}

.register-popup .wrapper,
.login-popup .wrapper,
.otp-popup .wrapper,
.register-complete-popup .wrapper {
  border: none;
  background-color: var(--dark-6);
}

.register-popup .title {
  font-size: 24px;
  font-weight: 700;

  text-align: center;
  margin-bottom: 34px;
}

.register-popup .social-media-btn-main {
  width: 100%;
  background: var(--dark-5);
  border: 1px solid white;
  border-radius: 12px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.4s, box-shadow 0.25s;
}

.register-popup .social-media-btn-main:not(:last-of-type) {
  margin-bottom: 24px;
}

.register-popup .social-media-btn-main img {
  position: absolute;
  left: 42px;
}

.register-popup .social-media-btn-main:hover {
  /* color: var(--bn-brand-color); */
  box-shadow: 2px 2px 5px 5px rgba(255, 255, 255, 0.1);
}

.register-popup .or {
  margin: 34px auto;
  font-size: 18px;
  font-weight: 700;

  text-align: center;
}

.register-popup .mobile-num {
  display: flex;
  align-items: center;
}

.register-popup .mobile-num .code {
  margin: 0 8px 0 6px;
}

.register-popup .mobile-num .code span {
  font-size: 18px;
  font-weight: 600;
}

.register-popup .mobile-num input {
  border: none;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border-bottom: 1px solid white;
  padding: 0 0 8px 0;
  background-color: transparent;
  line-height: 1;
}

.login-popup .back-btn-main,
.otp-popup .back-btn-main,
.register-complete-popup .back-btn-main {
  /*position: absolute;*/
  position: relative;
  /*top: 44px;*/
  top: 0;
  /*left: 44px;*/
  left: 0;
  cursor: pointer;
}

.login-popup .title,
.otp-popup .title {
  font-size: 24px;
  font-weight: 700;

  /* margin-top: 67px;
  margin-bottom: 34px; */
  margin-top: 24px;
  margin-bottom: 24px;
}

.login-popup .user-form,
.otp-popup .user-form {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.login-popup .user-form label,
.otp-popup .user-form label {
  width: 100%;
}

.login-popup .user-form input,
.otp-popup .user-form input {
  width: 100%;
  height: 50px;
  color: var(--light-5);
  background: var(--dark-5);
  border: 1px solid white;
  border-radius: 12px;
  padding: 0 24px;
  margin-top: 8px;
}

.login-popup .user-form input:not(:last-of-type),
.otp-popup .user-form input:not(:last-of-type) {
  margin-bottom: 16px;
}

.login-popup .user-form .blue-btn,
.otp-popup .blue-btn,
.register-complete-popup .blue-btn {
  width: 100%;
  height: 50px;
}

.login-popup .user-form .blue-btn {
  margin-top: 44px;
}

.otp-popup .title {
  margin-bottom: 14px;
}

.otp-popup .msg {
  font-size: 14px;
  font-weight: 700;

  color: white;
  margin-bottom: 20px;
}

.otp-popup .otp-input {
  /* margin-bottom: 174px; */
  margin-bottom: 24px;
}

.otp-popup .otp-input > div {
  flex-wrap: wrap;
}

.otp-popup .otp-input input:not(:last-of-type) {
  margin-right: 14px;
}

.otp-popup .otp-input input {
  color: var(--light-5);
}

.otp-popup .otp-input input:focus-within {
  border: 1px solid var(--bn-brand-color);
  color: var(--bn-brand-color);
}

.otp-popup .blue-btn.inactive {
  background: rgba(232, 48, 138, 0.25);
  pointer-events: none;
  cursor: unset;
}

/*.register-complete-popup .wrapper {*/
/*  text-align: center;*/
/*}*/

.register-complete-popup .title {
  font-size: 34px;
  font-weight: 700;

  margin-top: 32px;
  text-align: center;
}

.register-complete-popup .msg {
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 64px;
  text-align: center;
}

.seat-layout-popup .wrapper {
  padding: 0 60px 54px 60px;
  overflow: hidden;
}

.seat-layout-popup .title-row {
  background-color: var(--dark-2);
  /* height: 85px; */
  height: 60px;
  align-items: center;
  justify-content: center;
  margin: 0 -60px;
}

.seat-layout-popup .title-row .title {
  /* font-size: 34px; */
  font-size: 30px;
  font-weight: 700;

  text-align: center;
}

.seat-layout-popup .seat-layout-screen {
  /* margin-top: 44px;
  margin-bottom: 54px; */
  margin-top: 0;
  margin-bottom: 24px;
}

.seat-layout-popup .master-table {
  transform: none;
  margin: 0 auto 24px auto;
  pointer-events: none;
}

/* .seat-layout-popup .master-table .cell {
  height: 30px;
  width: 30px;
  padding: 4px;
  font-size: 12px;
} */

/* .seat-layout-popup .master-table .cell.seat {
  background-size: auto auto;
}

.seat-layout-popup .master-table .cell.lounger {
  background-size: auto auto;
}

.seat-layout-popup .master-table .cell.wheel-chair {
  background-size: auto auto;
} */

.seat-layout-popup .seat-layout-icons {
  margin-bottom: 0;
}

/* .seat-layout-popup .seat-layout-icons img {
  height: auto;
} */

.seat-layout-popup .seat-layout-icons p:not(:last-of-type) {
  margin-right: 28px;
}

.seat-layout-popup .entrance {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 34px;
}

.seat-layout-popup .entrance > div:first-of-type {
  margin-right: 97px;
}

.seat-layout-popup .entrance p {
  font-size: 14px;
  font-weight: 700;

  margin-left: 14px;
}

@media (max-width: 576px) {
  .show-details-popup .wrapper,
  .seat-type-popup .wrapper,
  .register-popup .wrapper,
  .login-popup .wrapper,
  .otp-popup .wrapper,
  .register-complete-popup .wrapper,
  .seat-layout-popup .wrapper,
  .share-ticket-popup .wrapper {
    padding: 44px 24px 24px 24px;
    border-radius: 12px;
  }

  /* .show-details-popup .close-btn-main,
  .seat-type-popup .close-btn-main,
  .register-popup .close-btn-main {
    top: 12px;
    right: 12px;
  } */

  .seat-type-popup .seat-picker {
    font-size: 14px;
  }

  .seat-type-popup .seat-picker .seat-picker-row {
    grid-template-columns: 34% 34% 24%;
    padding-bottom: 16px;
    margin-bottom: 16px;
    grid-gap: 4%;
  }

  .seat-type-popup .question {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .seat-type-popup .seat-picker .seat-picker-row .modifier {
    height: 28px;
    font-size: 16px;
  }

  .seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(odd) {
    width: 28px;
    font-size: 22px;
  }

  .seat-type-popup .seat-picker .seat-picker-row.total {
    font-size: 20px;
  }

  /* .login-popup .back-btn-main, */
  /* .otp-popup .back-btn-main, */
  /* .register-complete-popup .back-btn-main { */
  /* top: 24px; */
  /* top: 0; */
  /* left: 24px; */
  /* left: 0; */
  /* } */

  .otp-input input {
    /* width: 36px !important; */
    width: 100% !important;

    /* text-align: center; */
    height: 36px;
    margin-right: 6px;
  }

  .seat-layout-popup .wrapper {
    padding: 0 24px 34px 24px;
  }

  .seat-layout-popup .title-row {
    height: 60px;
    margin: 0 -24px;
  }

  .seat-layout-popup .title-row .title {
    font-size: 20px;
  }

  .seat-layout-popup .seat-layout-screen {
    margin-top: 24px;
    margin-bottom: 34px;
  }

  .seat-layout-popup .seat-layout-icons p:not(:last-of-type) {
    margin-right: 16px;
  }

  .share-ticket-popup .share-social-btn {
    justify-content: center !important;
  }

  .share-ticket-popup .share-social-btn div,
  .share-ticket-popup .share-social-btn a {
    width: 100%;
  }

  .share-ticket-popup .share-social-btn a {
    display: inline-block;
  }

  .share-ticket-popup .share-social-btn .social-btn {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .show-details-popup .modal-dialog,
  .seat-type-popup .modal-dialog {
    max-width: 626px;
  }

  .register-popup .modal-dialog,
  .login-popup .modal-dialog,
  .otp-popup .modal-dialog,
  .register-complete-popup .modal-dialog {
    max-width: 558px;
  }

  .seat-layout-popup .modal-dialog {
    max-width: 788px;
  }

  .share-ticket-popup .share-social-btn > div:not(:last-child) {
    margin-right: 16px;
  }
}

.format-radio-group .btn {
  width: 60px;
  height: 40px;
}

.lang-radio-group .btn {
  width: 124px;
  height: 40px;
}

@media (min-width: 1400px) {
  .payment-page .custom-accordian span {
    width: 100%;
  }

  .page-filters > div {
    margin-right: 50px;
  }

  .banner .banner-content .heading {
    font-size: 74px;
  }

  .banner .banner-content .desc {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    line-height: 25px;
  }

  .mini-festival-content .content-header .top {
    grid-template-columns: 16% 60%;
  }

  .mini-festival-content .content-header .bottom {
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: inherit;
  }
}

.md-icon ~ .title::after,
.date-picker-popup ~ .title::after,
.show-details-trigger::after {
  display: inline-block;
  content: "";
  margin-left: 0.355em;
  vertical-align: middle;
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  font-size: 16px;
}

.payment-booking-details .header {
  display: grid;
  /* grid-template-columns: 45% 45%; */
  grid-template-columns: 40% auto;
  align-items: center;
  padding-bottom: 16px;
  /* grid-column-gap: 16px; */
  grid-column-gap: 32px;
}

.seat-type-popup .payment-booking-details .header {
  grid-template-columns: 34% auto;
}

.payment-booking-details .header .right {
  /* margin-left: auto; */
}

.payment-booking-details .header .right > div {
  padding-bottom: 10px;
}

.payment-booking-details .header .movie-img {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 07px;
  /* max-width: 180px; */
}

.payment-booking-details .header .title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.payment-booking-details .header .features {
  font-size: 14px;
  font-weight: 600;
  padding-top: 05px;
}

.payment-booking-details .fs-13 {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.payment-booking-details .fs-10 {
  font-size: 10px;
  font-weight: 100;
}

.payment-booking-details .fs-16 {
  font-size: 16px !important;
}

.payment-booking-details .fs-22 {
  font-size: 22px !important;
}

.payment-booking-details .header .movie-imbd .value {
  color: var(--green-shade);
  font-weight: 700;
}

.payment-booking-details .header .movie-rating .value {
  color: var(--bn-brand-color);
  font-weight: 700;
}

.payment-booking-details .middle {
  border-top: 1px solid var(--light-4);
  border-bottom: 1px solid var(--light-4);
  padding-top: 20px;
}

.payment-booking-details .middle .modifier-btn {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-booking-details
  .middle
  .modifier-btn
  span:nth-child(odd):not(.inactive) {
  cursor: pointer;
}

.payment-booking-details
  .middle
  .modifier-btn
  span:nth-child(odd):not(.inactive):hover {
  transform: scale(1.15);
}

.payment-booking-details
  .middle
  .modifier-btn
  span:nth-child(odd):not(.inactive):active {
  transform: scale(0.95);
}

.payment-booking-details .middle .modifier-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.payment-booking-details .middle .modifier-btn > span:nth-child(odd) {
  font-size: 20px;
  border: 2px solid white;
  border-radius: 50%;
  color: var(--light-5);
  height: 28px;
  width: 28px;
  flex: 0 0 28px;
  box-sizing: content-box;
  line-height: 1;
  transition: transform 0.5s, background-color 0.5s;
}

.payment-booking-details .middle .modifier-btn > span:nth-child(odd):hover,
.fnb-card .fnb-content-row:last-of-type .modifier-btn span:nth-child(1):active {
  color: var(--primary);
  border-color: var(--primary);
}

.payment-booking-details .middle .modifier-btn > span:nth-child(even) {
  margin: 0 8px;
  width: 2ch;
}

.payment-booking-details .middle .modifier-btn > span:nth-child(odd).inactive {
  color: var(--dark-3);
  border-color: var(--dark-3);
  pointer-events: none;
  cursor: default;
}

.payment-booking-details .middle .checkbox-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 4px 24px;
}

.payment-booking-details .middle .checkbox-row > p {
  font-size: 14px;
}

.payment-booking-details .footer {
  padding: 20px 0;
  border-bottom: 1px solid var(--light-4);
  margin-bottom: 24px;
}

/* .payment-booking-details .middle .item {
  padding-bottom: 20px;
} */
